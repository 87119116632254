import Mock from "../mock";

const database = {
  information: {
    name: 'Kevin Vo',
    aboutContent: "An aspiring software engineer that is always looking to expand my knowledge and explore different areas of techology.",
    phone: '778-881-8959',
    nationality: 'Canadian',
    language: 'English, Vietnamese',
    email: 'KevinVo246@gmail.com',
    address: 'Vancouver, British Columbia, Canada',
    freelanceStatus: 'Available',
    socialLinks: {
      facebook: '',
      twitter: '',
      pinterest: '',
      behance: '',
      linkedin: 'https://www.linkedin.com/in/kevinvo96/',
      dribbble: '',
      github: 'https://github.com/KevyVo',
      youtube: 'https://www.youtube.com/channel/UC1S9KZtOxd6894rLYo2I0Jw?disable_polymer=true'
    },
    brandImage: '/images/brand-image.jpg',
    aboutImage: '/images/about-image-lgNew.jpg',
    aboutImageLg: '/images/about-image-lgNew.jpg',
    cvfile: 'https://kevinresumebucket.s3.us-west-2.amazonaws.com/Kevin_Vo_Resume.pdf'
  },
  services: [
    {
      title: "Software Development",
      icon: 'code',
      details: "I specalize in cloud, containerization and embedded development with a strong background of general programming."
    },
    {
      title: "Cloud Engineering",
      icon: 'cloud',
      details: "I am certified AWS solution architech and confident in using AWS. I am also willing to learn other cloud services too."
    },
    {
      title: "Electrical Design",
      icon: 'bolt',
      details: "I had studied and built many electrical circuit designs, I also have designed many PCBs on KiCad and had them produced. "
    },
    {
      title: "Prototyping",
      icon: 'invention',
      details: "I am able to make accurate 3D CAD models in Fusion360 efficiently and quickly produce them using a 3D printer."
    },
    {
      title: "Fabrication",
      icon: 'construction-hammer',
      details: "I had trained and operated on the CNC, waterJet cutter, laser cutter, lathe, milling machince, and welding."
    },
    {
      title: "Video Editing",
      icon: 'video',
      details: "I have worked for many professional film companies and have done countless video freelance jobs over the years."
    }
  ],
  reviews: [
    {
      id: 1,
      content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Expedita impedit nobis tempore quaerat quibusdam, aliquid maxime tempora.",
      author: {
        name: 'Burdette Turner',
        designation: 'Web Developer, Abc Company',
      }
    },
    {
      id: 2,
      content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Expedita impedit nobis tempore quaerat quibusdam.",
      author: {
        name: 'Susan Yost',
        designation: 'Client'
      }
    },
    {
      id: 3,
      content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit.",
      author: {
        name: 'Irving Feeney',
        designation: 'Fiverr Client'
      }
    }
  ],
  skills: [
    {
      title: "Python",
      value: 90
    },
    {
      title: "C++",
      value: 80
    },
    {
      title: "Golang",
      value: 80
    },
    {
      title: "C",
      value: 80
    },
    {
      title: "Javascript",
      value: 60
    },
    {
      title: "Java",
      value: 50
    }
  ],
  portfolios: [
    {
      id: 1,
      title: "Biometric Door Lock",
      subtitle: "A biometric dook lock with a flask webframe and Alexa capabilities",
      imageUrl: "/images/portfolio-image-1.jpg",
      largeImageUrl: ["/images/portfolio-image-1-lg.jpg"],
      url: 'https://github.com/KevyVo/Smart-Doorlock'
    },
    {
      id: 2,
      title: "Dice Game",
      subtitle: "A easily deployable AWS Javascript webpage with a fun dice rolling game. ",
      imageUrl: "/images/portfolio-image-4.jpg",
      largeImageUrl: [
        "/images/portfolio-image-4-lg.jpg",
        "/images/portfolio-image-4-lg2.jpg"
      ],
      url: 'https://github.com/KevyVo/Dice_Game'
    },
    {
      id: 3,
      title: "Tea & Coffee Mug",
      subtitle: "Beautiful mug with logo.",
      imageUrl: "/images/portfolio-image-2.jpg",
      url: 'https://pinterest.com'
    },
    {
      id: 4,
      title: "Pen Holder",
      subtitle: "A pen holder with beautiful design.",
      imageUrl: "/images/portfolio-image-3.jpg",
      largeImageUrl: ["/images/portfolio-image-3-lg.jpg"],
      url: '#'
    },
    {
      id: 5,
      title: "Mug",
      subtitle: "Mug with awesome style",
      imageUrl: "/images/portfolio-image-5.jpg",
      largeImageUrl: ["/images/portfolio-image-5-lg.jpg"]
    },
    {
      id: 6,
      title: "Pendrive",
      subtitle: "Free pendrive mockup design.",
      imageUrl: "/images/portfolio-image-6.jpg",
      largeImageUrl: ["/images/portfolio-image-6-lg.jpg"]
    },
    {
      id: 7,
      title: "Beautiful Pendrive",
      subtitle: "Pendrive with great design & flexible.",
      imageUrl: "/images/portfolio-image-7.jpg",
      largeImageUrl: ["/images/portfolio-image-7-lg.jpg"],
      url: 'https://twitter.com'
    },
    {
      id: 8,
      title: "Sticker",
      subtitle: "Clip sticker mockup design.",
      imageUrl: "/images/portfolio-image-8.jpg",
      largeImageUrl: ["/images/portfolio-image-8-lg.jpg"]
    },
    {
      id: 9,
      title: "Packet",
      subtitle: "Beautiful packet & product design.",
      imageUrl: "/images/portfolio-image-9.jpg",
      largeImageUrl: ["/images/portfolio-image-9-lg.jpg"]
    },
    {
      id: 10,
      title: "Pen Holder",
      subtitle: "A pen holder with beautiful design.",
      imageUrl: "/images/portfolio-image-3.jpg",
      largeImageUrl: ["/images/portfolio-image-3-lg.jpg"],
      url: '#'
    },
    {
      id: 11,
      title: "Coffee Mug",
      subtitle: "Awesome coffee mug design.",
      imageUrl: "/images/portfolio-image-4.jpg",
      largeImageUrl: [
        "/images/portfolio-image-4-lg.jpg",
        "/images/portfolio-image-4-lg2.jpg"
      ],
      url: 'https://facebook.com'
    },
    {
      id: 12,
      title: "Tea & Coffee Mug",
      subtitle: "Beautiful mug with logo.",
      imageUrl: "/images/portfolio-image-2.jpg",
      url: 'https://pinterest.com'
    },
    {
      id: 13,
      title: "T-shirt Mockup",
      subtitle: "A beautiful t-shirt mockup.",
      imageUrl: "/images/portfolio-image-1.jpg",
      largeImageUrl: ["/images/portfolio-image-1-lg.jpg"],
      url: 'https://dribbble.com'
    },
    {
      id: 14,
      title: "Mug",
      subtitle: "Mug with awesome style",
      imageUrl: "/images/portfolio-image-5.jpg",
      largeImageUrl: ["/images/portfolio-image-5-lg.jpg"]
    },
    {
      id: 15,
      title: "Pendrive",
      subtitle: "Free pendrive mockup design.",
      imageUrl: "/images/portfolio-image-6.jpg",
      largeImageUrl: ["/images/portfolio-image-6-lg.jpg"]
    },
    {
      id: 16,
      title: "Beautiful Pendrive",
      subtitle: "Pendrive with great design & flexible.",
      imageUrl: "/images/portfolio-image-7.jpg",
      largeImageUrl: ["/images/portfolio-image-7-lg.jpg"],
      url: 'https://twitter.com'
    },
    {
      id: 17,
      title: "Sticker",
      subtitle: "Clip sticker mockup design.",
      imageUrl: "/images/portfolio-image-8.jpg",
      largeImageUrl: ["/images/portfolio-image-8-lg.jpg"]
    },
    {
      id: 18,
      title: "Packet",
      subtitle: "Beautiful packet & product design.",
      imageUrl: "/images/portfolio-image-9.jpg",
      largeImageUrl: ["/images/portfolio-image-9-lg.jpg"]
    },
    {
      id: 19,
      title: "T-shirt Mockup",
      subtitle: "A beautiful t-shirt mockup.",
      imageUrl: "/images/portfolio-image-1.jpg",
      largeImageUrl: ["/images/portfolio-image-1-lg.jpg"],
      url: 'https://dribbble.com'
    },
    {
      id: 20,
      title: "Coffee Mug",
      subtitle: "Awesome coffee mug design.",
      imageUrl: "/images/portfolio-image-4.jpg",
      largeImageUrl: [
        "/images/portfolio-image-4-lg.jpg",
        "/images/portfolio-image-4-lg2.jpg"
      ],
      url: 'https://facebook.com'
    },
    {
      id: 21,
      title: "Tea & Coffee Mug",
      subtitle: "Beautiful mug with logo.",
      imageUrl: "/images/portfolio-image-2.jpg",
      url: 'https://pinterest.com'
    },
    {
      id: 22,
      title: "Pen Holder",
      subtitle: "A pen holder with beautiful design.",
      imageUrl: "/images/portfolio-image-3.jpg",
      largeImageUrl: ["/images/portfolio-image-3-lg.jpg"],
      url: '#'
    },
    {
      id: 23,
      title: "Mug",
      subtitle: "Mug with awesome style",
      imageUrl: "/images/portfolio-image-5.jpg",
      largeImageUrl: ["/images/portfolio-image-5-lg.jpg"]
    }
  ],
  experience: {
    workingExperience: [
      {
        id: 1,
        year: "May - December 2022",
        position: "Software Engineer Intern",
        company: "Aviatrix",
        details: "• Automated deployment of company`s containerized product by designing a Kubernetes inspired architecture, this reduced security breaches by 80% on customer networks by decoupling and containerizing Aviatrix’s product • Architecture included an orchestrator that used Podman bindings and custom designed operators to execute all administrative tasks, allowing customers to launch, update, and rollback their applications seamlessly."
      },
      {
        id: 2,
        year: "January – May 2022",
        position: "Software Engineer Intern",
        company: "MistyWest",
        details: "• Decreased recruitment searching time by 70% by creating a Python TensorFlow bot that uses machine learning to target ideal MistyWest candidates to be reported back to HR, the bot uses graph theory to search/crawl LinkedIn • Worked on a smart wearable in collaboration with Google that would be used for gesture control with smart home devices"
      },
      {
        id: 3,
        year: "Sept – December 2021",
        position: "Software Engineer Intern",
        company: "Tesla Motors Inc",
        details: "• Created tools and test software for Tesla model S,3,X,Y on the Core Test Engineering Technology Team • Created a Golang CAN tool that reduced manual battery debugging workflow by 80% • Provided technical support on other teams and fixed software bugs when case tickets are assigned"
      },
      {
        id: 4,
        year: "June - August 2020",
        position: "Solutions Architect Intern",
        company: "Amazon Web Services",
        details: "Vast knowledge of majority AWS services and building AWS demos.Architecting and developing cloud solutions base on customer`s requirement/projects. Consulting and demostrating software/cloud solutions for customers. Providing support for other technical roles. "
      }
    ],
    educationExperience: [
      {
        id: 1,
        year: "2021 - December 2023",
        graduation: "Bachelor of Computer Science",
        university: "Simon Fraser University",
        details: "Relevant courses: Data Strutures and Algorithms, Networking and Virtualization, Multimedia Web Development, System Analysis and Design, Designing with Raspberry Pi, C++, Python"
      },
      {
        id: 2,
        year: "2019 - 2021",
        graduation: "Diploma of Computing Science",
        university: "Douglas College",
        details: "An all around comprehensive program that is heavily based on mathematics and programming. All courses were fully transferable to a university for a degree."
      },
      {
        id: 3,
        year: "2020 - 2021",
        graduation: "Certifcate of Prototyping and Fabrication Technologies",
        university: "Douglas College",
        details: "A comprehensive program that is based on electrical design/protyping, material science, CAD design and machine operator training. Trained on the CNC, Water Jet-Cutter, 3D Printer, Laser Cutter, Lathe, Milling Machine, Welding and many other fabrication technologies."
      }
    ]
  },
  blogs: [
    {
      id: 1,
      title: 'AWS Amplify Identity Broker (Intern Project)',
      featuredImage: '/images/aws_Broker.png',
      filesource: '../../blog/amplifyBroker.md',
      createDay: "1",
      createMonth: 'September',
      createYear: "2020"
    },
    {
      id: 2,
      title: '3D Printed AI Nerf Sentry Turret',
      featuredImage: '/images/nerf_turret.JPG',
      filesource: '../../blog/nerfTurret.md',
      createDay: "3",
      createMonth: 'December',
      createYear: "2019"
    },
    {
      id: 3,
      title: 'Biometric Doorlock',
      featuredImage: '/images/doorlock.JPG',
      filesource: '../../blog/doorlock.md',
      createDay: "20",
      createMonth: 'January',
      createYear: "2020"
    },
    {
      id: 4,
      title: '3D Printed CNC Custom PCB VIce',
      featuredImage: '/images/vice.JPG',
      filesource: '../../blog/vice.md',
      createDay: "5",
      createMonth: 'October',
      createYear: "2020"
    },
    {
      id: 5,
      title: 'Dice Game',
      featuredImage: '/images/diceGame.png',
      filesource: '../../blog/diceGame.md',
      createDay: "5",
      createMonth: 'September',
      createYear: "2020"

    },
    {
      id: 6,
      title: 'Co-Pilot',
      featuredImage: '/images/co-pilot.JPG',
      filesource: '../../blog/coPilot.md',
      createDay: "10",
      createMonth: 'January',
      createYear: "2020"
    },
    {
      id: 7,
      title: 'Custom 3D Printed Staples Button',
      featuredImage: '/images/button.JPG',
      filesource: '../../blog/button.md',
      createDay: "24",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 8,
      title: 'Spotify automation to create Youtube playlist',
      featuredImage: '/images/blog-image-8.jpg',
      filesource: '../../blog/web-automation-with-python-language.md',
      createDay: "11",
      createMonth: 'October',
      createYear: "2020"
    },
    /*
    {
      id: 9,
      title: 'Think out of the box.',
      featuredImage: '/images/blog-image-9.jpg',
      filesource: '../../blog/think-out-of-the-box.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 10,
      title: 'Trending designs in 2020.',
      featuredImage: '/images/blog-image-1.jpg',
      filesource: '../../blog/trending-designs-in-2020.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 11,
      title: 'How to get 10k instagram followers?',
      featuredImage: '/images/blog-image-2.jpg',
      filesource: '../../blog/how-to-get-10k-instagram-followers.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 12,
      title: 'What NodeJS can do?',
      featuredImage: '/images/blog-image-3.jpg',
      filesource: '../../blog/what-nodejs-can-do.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 13,
      title: 'Futures of javascript.',
      featuredImage: '/images/blog-image-4.jpg',
      filesource: '../../blog/future-of-javascript.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 14,
      title: 'Popular javascript library in 2020.',
      featuredImage: '/images/blog-image-5.jpg',
      filesource: '../../blog/popular-javascript-library-in-2020.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 15,
      title: 'Promrammers must read books.',
      featuredImage: '/images/blog-image-6.jpg',
      filesource: '../../blog/programmers-must-read-books.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    } */
  ],
  contactInfo: {
    phoneNumbers: ['778-881-8959'],
    emailAddress: ['KevinVo246@gmail.com'],
    address: "Vancouver, British Columbia, Canada"
  }
}


Mock.onGet("/api/information").reply(config => {
  const response = database.information;
  return [200, response];
});

Mock.onGet("/api/services").reply(config => {
  const response = database.services;
  return [200, response];
});

Mock.onGet("/api/reviews").reply(config => {
  const response = database.reviews;
  return [200, response];
});

Mock.onGet("/api/skills").reply(config => {
  const response = database.skills;
  return [200, response];
});

Mock.onGet("/api/portfolios").reply(config => {
  const response = database.portfolios;
  return [200, response];
});

Mock.onGet("/api/experience").reply(config => {
  const response = database.experience;
  return [200, response];
});

Mock.onGet("/api/blog").reply(config => {
  const response = database.blogs;
  return [200, response];
});

Mock.onGet("/api/contactinfo").reply(config => {
  const response = database.contactInfo;
  return [200, response];
});